import * as React from "react";
import { Link } from "gatsby";
import "./styles.scss";

const UserMenu = ({ isLogged }) => {
    return <div>
        { (typeof window !== "undefined") && !window.location.pathname.includes('home') ? 
            ( 
                <div className="userMenu">
                    <ul className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/">Homepage</Link>
                        </li> 

                        {isLogged && (
                            <>
                                {/* <li className="breadcrumb-item">
                                    <Link to="/all-rewards/">My Treats</Link>
                                </li> */}

                                <li className="breadcrumb-item">
                                    <Link to="/account/">My Profile</Link>
                                </li>
                            </>
                        )}
                    </ul>
                </div>
            ) : null 
        }
    </div>;
};

export default UserMenu;
