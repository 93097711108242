import React from "react";
import CookiesMessage from "../CookiesMessage/CookiesMessage";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import "../../_styles/index.scss";
import "./styles.scss";

const Layout = ({
    title,
    children,
    hideCookies,
    hideFooterNavigation,
    customClass
}) => {
    return (
        <>
            <title>
                {title ? `${title} | Mubadala Silicon Valley Classic` : `Mubadala Silicon Valley Classic`}
            </title>
            <link rel="icon" type="image/x-icon" href="/images/favicon.ico"/>

            <main className={`container ${customClass}`}>
                <Header /> 

                <div id="content">
                    {children}
                </div>

                {
                    !hideCookies
                        ? <CookiesMessage />
                        : null
                }

                <Footer hideFooterNavigation={hideFooterNavigation}/>
            </main>
        </>
    );
};

export default Layout;
