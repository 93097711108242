import * as React from "react";
import { Link } from "gatsby";

export default function DevTools() {
    if (!process.env.GATSBY_SHOW_DEV_TOOLS || process.env.GATSBY_SHOW_DEV_TOOLS === "0") {
        return null;
    }

    return (
        <>
            <hr />
            <Link to="/">Home</Link>
            &nbsp;/ <Link to="/login">Login</Link>
            &nbsp;/ <Link to="/registration">Registration</Link>
            &nbsp;/ <Link to="/account">Account</Link>
            &nbsp;/ <Link to="/reward-lander">Reward Lander</Link>
            &nbsp;/ <Link to="/qr-rewards">QR Rewards</Link>
            &nbsp;/ <Link to="/all-rewards">All Rewards</Link>
            &nbsp;/ <Link to="/contacts">Contacts</Link>
            &nbsp;/ <Link to="/cookies">Cookies</Link>
            &nbsp;/ <Link to="/privacy">Privacy</Link>
            &nbsp;/ <Link to="/404">404</Link>
            &nbsp;/ <Link to="/problem?content=Query%20Content">Problem</Link>
            &nbsp;/ <Link to="/terms">Terms</Link>
            &nbsp;/ <Link to="/event-maps">Event Maps</Link>
            &nbsp;/ <Link to="/event-guides">Event Guides</Link>
            &nbsp;/ <Link to="/event-promotions">Event Promotions</Link>
            &nbsp;/ <Link to="/mubadala-info">Mubadala Info</Link>
        </>
    );
}
