import * as React from "react";
import AccordionTab from "../AccordionTab/AccordionTab";
import StyledLink from "../StyledLink/StyledLink";
import "./styles.scss";
import DevTools from "../DevTools/DevTools";
import { Link } from "gatsby";

export default function Footer({ hideFooterNavigation }) {
    return (
        <>
            <div className="footer">
                {!hideFooterNavigation ? (
                    <div className="accordion-tabs">
                        <Link
                            to="/terms/">
                            Terms and Conditions
                        </Link>

                        <Link
                            to="/privacy/">
                            Privacy Policy
                        </Link>

                        <Link
                            to="/cookies/">
                            Cookie Policy
                        </Link>

                        <Link
                            to="/contacts/">
                            Contact Us
                        </Link>
                    </div>
                ) : null}

                <div className="footer-logos">
                    <div className="mubadala-logos">
                        <img
                            src="/images/silicon-valley/footer-logo-3.png"
                            className="mubadala"
                            alt="Mubadala"/>

                        <img
                            src="/images/silicon-valley/footer-logo-2.png"
                            className="usopen"
                            alt="Mubadala"/>
                    </div>

                    <img
                        src="/images/logo.png"
                        className="svc"
                        alt="Mubadala"/>
                </div>

                <p className="footer-fullyear powered">
                    Powered by  
                    <img
                        src="/images/fabacus-logo-white.png"
                        className="logo"
                        alt="Fabacus"/>
                </p>
            </div>

            <DevTools />
        </>
    );
}
