import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import Avatar from "../../../public/images/icons/avatar.svg";
import "./styles.scss";
import Database from "../../_utils/Database";
import UserMenu from "../UserMenu/UserMenu";

const Header = () => {
    const [isLogged, setIsLogged] = useState(false);

    useEffect(() => {
        if (Database.getUserToken()) {
            setIsLogged(true);
        }
    }, [isLogged]);

    return (
        <>
            <nav className="header">
                <div className="header-content">
                    <Link to="/home">
                        <img
                            src="/images/logo.png"
                            className="logo"
                            alt="Silicon Valley"
                        />
                    </Link>

                    {/* {isLogged && (
                        <Link to="/account" className="user-link">
                            <img
                                src={Avatar}
                                alt="avatar"
                                className="avatar"
                            />
                        </Link>
                    )} */}
                </div>
            </nav>

            <UserMenu isLogged={isLogged}/>
        </>
    );
};
export default Header;
